<template>
    <div>
        <ChartList
            :taskStatistics="taskStatistics"
            :visibleСharts="['all', 'completed']"
            adaptiveChart />
        <div class="font-semibold mb-2 mt-4">Мои задачи</div>
        <TaskTable 
            :organization="organization"/>
    </div>
</template>

<script>
import TaskTable from '@apps/Team/components/Statistics/TaskTable'
import ChartList from './ChartList.vue'

export default {
    components: {
        ChartList,
        TaskTable
    },
    props: {
        organization: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            taskStatistics: {},
        }
    },
    created() {
        this.getStatisticsByOrganization()
    },
    methods: {
        async getStatisticsByOrganization() {
            const params = {}            
            const url = `/users/my_organizations/${this.organization.id}/task_count/`
            try {
                const { data } = await this.$http.get(url, params)
                this.taskStatistics = data
            } catch(error) {
                console.error(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>